import React from 'react'
import Appointment from '../components/Appointment'

export default function AppointmentPage() {
    return (
        <>
            <Appointment />
        </>
    )
}
