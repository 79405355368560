import React, { useContext } from 'react'
import { DataContext } from '../context/context'
import { Link } from 'react-router-dom';
export default function Services() {
    const { data } = useContext(DataContext);
    if (!data) return;
    // console.log(data);

    return (
        <>
            {/* <!-- Services Section --> */}
            <section id="services" className="services section">

                {/* <!-- Section Title --> */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>{data.service_title}</h2>
                    <p>{data.service_sub_title}</p>
                </div>
                {/* <!-- End Section Title --> */}

                <div className="container">

                    <div className="row gy-4">
                        {data.service_box.map((data, index) => (
                            <div key={index} className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <i className={`fas ${data.service_icon}`}></i>
                                    </div>
                                    <Link to={'/'} className="stretched-link">
                                        <h3>{data.service_box_title}</h3>
                                    </Link>
                                    <p>{data.service_box_sub_title}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

            </section>
            {/* <!-- /Services Section --> */}
        </>
    )
}
