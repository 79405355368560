import React, { useContext, useEffect } from 'react'
import { DataContext } from '../context/context'
import PureCounter from '@srexi/purecounterjs';
export default function Status() {
    const { data } = useContext(DataContext);
    console.log('data', data);

    new PureCounter();
    useEffect(() => {
        if (window.PureCounter) {
            new window.PureCounter();
        }
    }, [data]);

    if (!data) return;

    return (
        <>
            {/* <!-- Stats Section --> */}
            <section id="stats" className="stats section light-background">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4">
                        {data.counter_list.map((data, index) => (
                            <div key={index} className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                                <i className={`fa-solid ${data.counter_icon}`}></i>
                                <div className="stats-item">
                                    <span data-purecounter-start="0" data-purecounter-end={data.counter} data-purecounter-duration="1"
                                        className="purecounter"></span>
                                    <p>{data.counter_name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* <!-- /Stats Section --> */}
        </>
    )
}
