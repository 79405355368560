import React, { useContext, useState } from 'react'
import { DataContext } from '../context/context'
export default function Faq() {
    const { data } = useContext(DataContext);
    const [activeIndex, setActiveIndex] = useState(0);
    if (!data) return;
    const toggleFaq = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            {/* <!-- Faq Section --> */}
            <section id="faq" className="faq section light-background">

                {/* <!-- Section Title --> */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>{data.faq_title}</h2>
                    <p>{data.faq_sub_title}</p>
                </div>
                {/* <!-- End Section Title --> */}

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">

                            <div className="faq-container">
                                {data.faq.map((faqData, index) => (
                                    <div key={index} className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}
                                        onClick={() => toggleFaq(index)}>
                                        <h3>{faqData.question}</h3>
                                        <div className="faq-content">
                                            <p>{faqData.answer}</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>
                                ))}
                                {/* <!-- End Faq item--> */}

                            </div>

                        </div>
                        {/* <!-- End Faq Column--> */}

                    </div>

                </div>

            </section>
            {/* <!-- /Faq Section --> */}

        </>
    )
}
