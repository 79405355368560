import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import qs from 'qs';
export default function ContactUs() {
	const [message, setMessage] = useState();
	const [showAlert, setShowAlert] = useState(false);
	useEffect(() => {
		// Remove the alert after 10 seconds
		if (showAlert) {
			const timer = setTimeout(() => {
				setShowAlert(false);
			}, 5000); // 10000 milliseconds = 5 seconds
			return () => clearTimeout(timer); // Cleanup the timer when component unmounts or on next render
		}
	}, [showAlert]);

	const { register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting }
	} = useForm();

	const onSubmit = async (data) => {
		try {

			if (!data.username || !data.email || !data.subject || !data.message) {
				setMessage("Please fill in all the required fields.");
				setShowAlert(true);
				return; // Stop further execution if validation fails
			}

			const response = await axios.post('https://wppremiumplugins.com/medilabs/wp-content/themes/twentytwentyfive/contact-api.php',
				qs.stringify(data), // Convert data to URL-encoded format
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);

			// console.log(response); // Log the response

			reset();

			if (response.status === 200) {
				const result = response.data;
				setMessage(result.message || "Form submitted successfully!");
				setShowAlert(true);
			} else {
				setShowAlert(true);
				setMessage(response.data.message || "Something went wrong");
			}
		} catch (error) {
			console.error('Error submitting the form:', error);
		}
	};

	return (
		<section id="contact" className="contact section">
			<div className="container section-title" data-aos="fade-up">
				<h2>Contact</h2>
				<p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
			</div>
			{/* <div className="mb-5" data-aos="fade-up" data-aos-delay="200">
                <iframe style="border:0; width: 100%; height: 270px;"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus"
                    frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div> */}
			{/* <!-- End Google Maps --> */}
			<div className="container" data-aos="fade-up" data-aos-delay="100">
				<div className="row gy-4">
					<div className="col-lg-4">
						<div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
							<i className="bi bi-geo-alt flex-shrink-0"></i>
							<div>
								<h3>Location</h3>
								<p>A108 Adam Street, New York, NY 535022</p>
							</div>
						</div>
						{/* <!-- End Info Item --> */}

						<div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
							<i className="bi bi-telephone flex-shrink-0"></i>
							<div>
								<h3>Call Us</h3>
								<p>+1 5589 55488 55</p>
							</div>
						</div>
						{/* <!-- End Info Item --> */}

						<div className="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
							<i className="bi bi-envelope flex-shrink-0"></i>
							<div>
								<h3>Email Us</h3>
								<p>info@example.com</p>
							</div>
						</div>
						{/* <!-- End Info Item --> */}

					</div>


					<div className="col-lg-8">
						<form onSubmit={handleSubmit(onSubmit)} className="php-email-form" data-aos="fade-up" data-aos-delay="200">
							{showAlert && (<div className="alert alert-success" role="alert">{message}</div>)}
							<div className="row gy-4">

								{/* Username Field with Validation */}
								<div className="col-md-6">
									<input
										{...register("username", {
											required: { value: true, message: "This field is required" },
											minLength: { value: 3, message: 'Min length is 3' },
											maxLength: { value: 10, message: 'Max length is 10' }
										})}
										type="text"
										className="form-control"
										placeholder="Your Name"
									/>
									{errors.username && <span className='error'>{errors.username.message}</span>}
								</div>

								{/* Email Field with Validation */}
								<div className="col-md-6">
									<input
										{...register("email", {
											required: { value: true, message: "Email is required" },
											pattern: { value: /^\S+@\S+$/, message: "Invalid email address" }
										})}
										type="email"
										className="form-control"
										placeholder="Your Email"
									/>
									{errors.email && <span className='error'>{errors.email.message}</span>}
								</div>

								{/* Subject Field */}
								<div className="col-md-12">
									<input
										{...register("subject", { required: "Subject is required" })}
										type="text"
										className="form-control"
										placeholder="Subject"
									/>
									{errors.subject && <span className='error'>{errors.subject.message}</span>}
								</div>

								{/* Message Field */}
								<div className="col-md-12">
									<textarea
										{...register("message", { required: "Message is required" })}
										className="form-control"
										rows="6"
										placeholder="Message"
									></textarea>
									{errors.message && <span className='error'>{errors.message.message}</span>}
								</div>

								{/* Submit Button */}
								<div className="col-md-12 text-center">
									<button type="submit" disabled={isSubmitting}> {isSubmitting ? "LOADING..." : "Send Message"}</button>
								</div>

							</div>
						</form>
					</div>

				</div>
			</div>

		</section>
	);
}
