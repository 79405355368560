import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/main.css';
import './assets/js/main';

import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import AppointmentPage from './pages/AppointmentPage';
import { Routes, Route } from 'react-router-dom';
import { DataProvider } from './context/context';
// import Loder from './components/Loder';
import ScrollToTop from './components/ScrollToTop';
import Blog from './pages/Blog';

function App() {
  return (
    <DataProvider>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/appointment" element={<AppointmentPage />} />
      </Routes>
      <Footer />

    </DataProvider>
  );
}

export default App;
