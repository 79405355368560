import React, { useContext, useEffect } from 'react';
import { DataContext } from '../context/context';
import { Link } from 'react-router-dom';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css'; // Import the CSS for GLightbox

export default function Gallery() {
    const { data } = useContext(DataContext);

    // console.log(data);

    useEffect(() => {
        // Initialize GLightbox after the component mounts
        const lightbox = GLightbox({
            selector: '.glightbox',
        });

        // Cleanup the GLightbox instance on component unmount
        return () => {
            lightbox.destroy();
        };
    }, [data]);

    if (!data) return null; // Return null to avoid rendering until data is loaded

    return (
        <>
            <section id="gallery" className="gallery section">
                {/* Section Title */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>{data.gallery_title}</h2>
                    <p>{data.gallery_sub_title}</p>
                </div>
                {/* End Section Title */}

                <div className="container-fluid" data-aos="fade-up" data-aos-delay="100">
                    <div className="row g-0">
                        {data.image_gallery.map((gallery, index) => (
                            <div key={index} className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <Link
                                        to={gallery} // Link to the full-size image URL
                                        className="glightbox"
                                        data-gallery="images-gallery"
                                    >
                                        {gallery ? (
                                            <img
                                                src={gallery} // Use the thumbnail URL
                                                alt={`Gallery item ${index + 1}`}
                                                className="img-fluid"
                                            />
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
