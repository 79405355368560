import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function BlogPost() {
    const [postData, setPostData] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [filteredData, setFilteredData] = useState([]); // State for filtered posts

    useEffect(() => {
        async function getPostData() {
            const username = 'admin'; // Replace with your WordPress username
            const appPassword = 'admin'; // Replace with your app password
            // const headers = { "Content-Type": "application/json" };
            try {
                const response = await axios.get('https://wppremiumplugins.com/medilabs/wp-json/wp/v2/posts', {
                    auth: {
                        username: username,
                        password: appPassword
                    }
                });
                console.log(response.data);

                setPostData(response.data);
                setFilteredData(response.data); // Set initial filtered data to all posts
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        }

        getPostData();
    }, []);

    // Handle search input change
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // Filter the posts based on the search query
        const filtered = postData.filter(post =>
            post.title.rendered.toLowerCase().includes(query.toLowerCase()) ||
            post.excerpt.rendered.toLowerCase().includes(query.toLowerCase()) ||
            post.categories.some(cat => cat.name.toLowerCase().includes(query.toLowerCase()))
        );

        setFilteredData(filtered);
    };

    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className="search text-end mb-4">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div className="container blog_post">
                    <div className="row">
                        {filteredData.length > 0 ? (
                            filteredData.map((data, index) => (
                                <div className="col-md-4 mb-5" key={index}>
                                    <div className="card">
                                        <div className="card-header">
                                            <img
                                                src={data.featured_media}
                                                alt=""
                                            />
                                        </div>
                                        <div className="card-body">
                                            {data.categories && data.categories.length > 0 && (
                                                <span className="tag tag-teal">
                                                    {data.categories.map((category) => category.name).join(', ')}
                                                </span>
                                            )}
                                            <h4>{data.title.rendered}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No posts found</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
