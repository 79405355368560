import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../context/context';
import AboutPopup from './AboutPopup';
export default function About() {
	const { data } = useContext(DataContext);
	const [popupVisible, setPopupVisible] = useState(false);

	if (!data) return;

	const handlePlayClick = (e) => {
		e.preventDefault(); // Prevent default link behavior
		setPopupVisible(true);
	};

	return (
		<>
			{/* <!-- About Section --> */}
			<section id="about" className="about section">
				<div className="container">
					<div className="row gy-4 gx-5">
						<div className="col-lg-6 position-relative align-self-start" data-aos="fade-up" data-aos-delay="200">
							{data?.about_image ? (<img src={data?.about_image} className="img-fluid" alt="about-img.jpg" />) : (
								<p>Loading...</p>
							)}
							<Link onClick={handlePlayClick} id="paly_btn" className="pulsating-play-btn"></Link>
						</div>
						<div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
							<h3>{data.about_title}</h3>
							<p>
								{data.about_short_descriptio}
							</p>

							<ul>
								{data.about_list.map((list, index) => (
									<li key={index} className={`list-item-${index}`}>
										<i className={list.fa_icon}></i>
										<div>
											<h5>{list.list_title}</h5>
											<p>{list.list_sub_title}</p>
										</div>
									</li>
								))}

							</ul>

						</div>

					</div>
				</div>
			</section>
			<AboutPopup show={popupVisible} onClose={() => setPopupVisible(false)} popupUrl={data.about_popup_video} />
		</>
	)
}
