import React, { useContext, useState } from 'react';
import { DataContext } from '../context/context';

export default function Departments() {
    const [activeTab, setActiveTab] = useState(0); // Default active tab index
    const { data } = useContext(DataContext);
    if (!data) return null;

    return (
        <section id="departments" className="departments section">
            <div className="container section-title" data-aos="fade-up">
                <h2>{data.departments_title}</h2>
                <p>{data.departments_sub_title}</p>
            </div>

            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row">
                    <div className="col-lg-3">
                        <ul className="nav nav-tabs flex-column">
                            {data.faculty_tabs.map((tab, index) => (
                                <li key={index} className="nav-item">
                                    <a className={`nav-link ${activeTab === index ? 'active show' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent default anchor behavior
                                            setActiveTab(index); // Set active tab
                                        }}
                                        href={`#departments-tab-${index}`} >
                                        {tab.tabs}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-9 mt-4 mt-lg-0">
                        <div className="tab-content">
                            {data.faculty_tabs_content.map((tabContent, index) => (
                                <div
                                    key={index}
                                    className={`tab-pane ${activeTab === index ? 'active show' : ''}`}
                                    id={`departments-tab-${index}`}
                                >
                                    <div className="row">
                                        <div className="col-lg-8 details order-2 order-lg-1">
                                            <h3>{tabContent.tab_title}</h3>
                                            <p className="fst-italic">{tabContent.tab_quotes}</p>
                                            <p>{tabContent.tab_description}</p>
                                        </div>
                                        <div className="col-lg-4 text-center order-1 order-lg-2">
                                            {tabContent?.tab_image ? (
                                                <img
                                                    src={tabContent?.tab_image}
                                                    alt={tabContent.tab_title}
                                                    className="img-fluid"
                                                />
                                            ) : (
                                                <p>Loading...</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
