import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
	const [menu, setmenu] = useState(null);
	const [activeLink, setActiveLink] = useState("#hero"); // Default to home link
	const { pathname } = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		// Fetch menu items
		const getmenu = async () => {
			const url = " https://wppremiumplugins.com/medilabs/wp-json/wp/v2/top-menu";

			try {
				const response = await fetch(url);

				if (!response.ok) {
					throw new Error(`Response status: ${response.status}`);
				}

				const json = await response.json();
				setmenu(json);
			} catch (error) {
				console.error(error.message);
			}
		};

		getmenu();

		// Observe section visibility
		const observerCallback = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const sectionId = `#${entry.target.id}`;
					setActiveLink(sectionId);
				}
			});
		};

		const observerOptions = {
			root: null,
			threshold: 0.6, // Trigger when 60% of the section is visible
		};

		const observer = new IntersectionObserver(observerCallback, observerOptions);

		// Observe each section
		const sections = document.querySelectorAll("section");
		sections.forEach((section) => observer.observe(section));

		return () => {
			sections.forEach((section) => observer.unobserve(section));
		};
	}, []);

	const handleLinkClick = useCallback((href) => {
		setActiveLink(href);

		if (pathname.includes("appointment")) {
			navigate("/")
		}

		if (pathname.includes("blog")) {
			navigate("/")
		}

		const yOffset = -112;
		const element = document.getElementById(href?.replace("#", ""));
		const y = element?.getBoundingClientRect()?.top + window.scrollY + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth' });

		// Scroll smoothly to the section
		// const section = document.querySelector(href);
		// if (section) {
		// 	section.scrollIntoView({ behavior: "smooth" });
		// }
	}, [pathname]);

	return (
		<>
			<header id="header" className="header sticky-top">
				<div className="topbar d-flex align-items-center">
					<div className="container d-flex justify-content-center justify-content-md-between">
						<div className="contact-info d-flex align-items-center">
							<i className="bi bi-envelope d-flex align-items-center">
								<a href="mailto:contact@example.com">contact@example.com</a>
							</i>
							<i className="bi bi-phone d-flex align-items-center ms-4">
								<span>+1 5589 55488 55</span>
							</i>
						</div>
						<div className="social-links d-none d-md-flex align-items-center">
							<Link to="/" className="twitter">
								<i className="bi bi-twitter-x"></i>
							</Link>
							<Link to="/" className="facebook">
								<i className="bi bi-facebook"></i>
							</Link>
							<Link to="/" className="instagram">
								<i className="bi bi-instagram"></i>
							</Link>
							<Link to="/" className="linkedin">
								<i className="bi bi-linkedin"></i>
							</Link>
						</div>
					</div>
				</div>
				{/* <!-- End Top Bar --> */}

				<div className="branding d-flex align-items-center">
					<div className="container position-relative d-flex align-items-center justify-content-between">
						<Link to="/" className="logo d-flex align-items-center me-auto">
							<h1 className="sitename">Medilab</h1>
						</Link>

						<nav id="navmenu" className="navmenu">
							<ul>
								{Array.isArray(menu) && menu.length > 0
									? menu.map((data, index) => {
										return (
											<li key={index}>
												<Link
													to={'/home' + data.href}
													className={activeLink === data.href ? "active" : ""}
													onClick={(e) => {
														e.preventDefault();
														handleLinkClick(data.href)
													}}
												>
													{data.name}
													<br />
												</Link>
											</li>
										);
									})

									: null}

								{/* Conditionally Add Custom Blog Link */}
								{
									<li>
										<Link to="/blog" className={activeLink === "/blog" ? "active" : ""}>
											Blog
											<br />
										</Link>
									</li>
								}
							</ul>

							<i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
						</nav>


						<Link to={'/appointment'} className="cta-btn d-none d-sm-block">
							Make an Appointment
						</Link>
					</div>
				</div>
			</header>
		</>
	);
}
