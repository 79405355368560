import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const username = 'admin'; // Replace with your WordPress username
            const appPassword = 'admin'; // Replace with your app password
            const pageId = 9; // Page ID
            try {
                // Create a Basic Authentication header
                const headers = {
                    Authorization: 'Basic ' + btoa(`${username}:${appPassword}`),
                };

                // Fetch page data using axios
                // const pageResponse = await axios.get(
                //     `http://localhost/medilab/backend/wp-json/wp/v2/pages/${pageId}`,
                //     { headers }
                // );

                // const headers = { "Content-Type": "application/json" };
                const apiUrl = process.env.REACT_APP_API_URL; // Access .env variable

                const pageResponse = await axios.get(
                    `${apiUrl}/pages/${pageId}`,
                    { headers }
                );

                setData(pageResponse.data.acf);
            } catch (error) {
                console.log('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <DataContext.Provider value={{ data, loading }}>
            {children}
        </DataContext.Provider>
    );
};
