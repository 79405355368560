import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import qs from 'qs';

export default function Appointment() {
    const [message, setMessage] = useState();
    const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm();
    const [showAlert, setShowAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    useEffect(() => {
        // Remove the alert after 5 seconds
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000); // 5000 milliseconds = 5 seconds
            return () => clearTimeout(timer); // Cleanup the timer when component unmounts or on next render
        }
    }, [showAlert]);

    const onSubmit = async (data) => {

        if (!data.name || !data.email || !data.date || !data.department || !data.doctor || !data.message) {
            setMessage("Please fill in all the required fields.");
            setErrorAlert(true);
            console.log('test');
            return;
        }

        try {
            const response = await axios.post('https://wppremiumplugins.com/medilabs/themes/twentytwentyfive/appointment-api.php',
                qs.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            // Reset the form after submission
            reset();

            if (response.status === 200) {
                const result = response.data;
                setMessage(result.message || "Form submitted successfully!");
                setShowAlert(true);
            } else {
                setShowAlert(true);
                setMessage(response.data.message || "Something went wrong");
            }

        } catch (error) {
            console.error(error);
            setShowAlert(true);
            setMessage("An error occurred while submitting the form.");
        }
    };

    return (
        <section id="appointment" className="appointment section">
            <div className="container section-title" data-aos="fade-up">
                <h2>Appointment</h2>
                <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
            </div>

            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <form onSubmit={handleSubmit(onSubmit)} className="php-email-form">
                    {showAlert && <div className="alert alert-success" role="alert">{message}</div>}
                    {errorAlert && <div className="alert alert-danger" role="alert">{message}</div>}
                    <div className="row">
                        <div className="col-md-4 form-group">
                            <input
                                {...register("name", {
                                    required: { value: true, message: "This field is required" },
                                    minLength: { value: 3, message: 'Min length is 3' },
                                    maxLength: { value: 10, message: 'Max length is 10' }
                                })}
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                                onInput={(e) => {
                                    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                                }}
                            />
                            {errors.name && <span className="error">{errors.name.message}</span>}
                        </div>
                        <div className="col-md-4 form-group mt-3 mt-md-0">
                            <input
                                {...register("email", {
                                    required: { value: true, message: "Email is required" },
                                    pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,}$/, message: "Invalid email format" }
                                })}
                                type="email"
                                className="form-control"
                                placeholder="Your Email"
                            />
                            {errors.email && <span className="error">{errors.email.message}</span>}
                        </div>
                        <div className="col-md-4 form-group mt-3 mt-md-0">
                            <input
                                {...register("phone", {
                                    required: { value: true, message: "Phone is required" },
                                    pattern: { value: /^[0-9]{10}$/, message: "Phone must be 10 digits" }
                                })}
                                type="tel"
                                className="form-control"
                                placeholder="Your Phone"
                            />
                            {errors.phone && <span className="error">{errors.phone.message}</span>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 form-group mt-3">
                            <input
                                {...register("date", { required: { value: true, message: "Appointment date is required" } })}
                                type="datetime-local"
                                className="form-control"
                                placeholder="Appointment Date"
                            />
                            {errors.date && <span className="error">{errors.date.message}</span>}
                        </div>
                        <div className="col-md-4 form-group mt-3">
                            <select
                                {...register("department", { required: { value: true, message: "Please select a department" } })}
                                className="form-select"
                            >
                                <option value="">Select Department</option>
                                <option value="Department 1">Department 1</option>
                                <option value="Department 2">Department 2</option>
                                <option value="Department 3">Department 3</option>
                            </select>
                            {errors.department && <span className="error">{errors.department.message}</span>}
                        </div>
                        <div className="col-md-4 form-group mt-3">
                            <select
                                {...register("doctor", { required: { value: true, message: "Please select a doctor" } })}
                                className="form-select"
                            >
                                <option value="">Select Doctor</option>
                                <option value="Doctor 1">Doctor 1</option>
                                <option value="Doctor 2">Doctor 2</option>
                                <option value="Doctor 3">Doctor 3</option>
                            </select>
                            {errors.doctor && <span className="error">{errors.doctor.message}</span>}
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <textarea
                            {...register("message")}
                            className="form-control"
                            rows="5"
                            placeholder="Message (Optional)"
                        ></textarea>
                    </div>

                    <div className="mt-3">
                        <div className="loading">Loading...</div>
                        <div className="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                        <div className="text-center">
                            <button type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Sending..." : "Make an Appointment"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
