import React from 'react'
import HeroSection from '../components/HeroSection'
import About from '../components/About'
import Status from '../components/Status'
import Services from '../components/Services'
import Departments from '../components/Departments'
import Doctor from '../components/Doctor'
import Faq from '../components/Faq'
import Testimonials from '../components/Testimonials'
import Gallery from '../components/Gallery'
import ContactUs from '../components/ContactUs'

export default function Home() {
    return (
        <>
            <HeroSection />
            <About />
            <Status />
            <Services />
            <Departments />
            <Doctor />
            <Faq />
            <Testimonials />
            <Gallery />
            <ContactUs />

        </>
    )
}
