import React, { useContext, useEffect } from 'react';
import { DataContext } from '../context/context';
import Swiper from 'swiper/bundle';
import 'swiper/css';
import 'swiper/css/pagination';

export default function Testimonials() {
    const { data } = useContext(DataContext);

    useEffect(() => {
        // Initialize Swiper only if Swiper container is present
        if (data) {
            new Swiper('.swiper-container', {
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: false, // Disable navigation arrows
            });

        }
    }, [data]); // Depend on data to ensure Swiper initializes correctly when data is available

    if (!data) return null;

    return (
        <section id="testimonials" className="testimonials section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 info" data-aos="fade-up" data-aos-delay="100">
                        <h3>{data.testimonials_title}</h3>
                        <p>{data.testimonials_sub_title}</p>
                    </div>
                    <div className="col-lg-7" data-aos="fade-up" data-aos-delay="200">
                        <div className="swiper swiper-container">
                            <div className="swiper-wrapper">
                                {data.testimonials.map((testimo, index) => (
                                    <div key={index} className="swiper-slide">
                                        <div className="testimonial-item">
                                            <div className="d-flex">
                                                {testimo?.user_picture ? (
                                                    <img
                                                        src={testimo.user_picture}
                                                        className="testimonial-img flex-shrink-0"
                                                        alt={testimo.user_name}
                                                    />
                                                ) : (
                                                    <p>Loading...</p>
                                                )}
                                                <div>
                                                    <h3>{testimo.user_name}</h3>
                                                    <h4>{testimo.user_designation}</h4>
                                                    <div className="stars">
                                                        {Array.from({ length: Math.min(testimo.user_review_star, 5) }).map(
                                                            (_, starIndex) => (
                                                                <i key={starIndex} className="bi bi-star-fill"></i>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                <span>{testimo.user_review}</span>
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
