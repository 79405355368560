import React, { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DataContext } from '../context/context';
// import Loader from './Loader';
export default function HeroSection() {
    // const [data, setData] = useState(null);
    const { data } = useContext(DataContext);
    if (!data) return <div className="loader-container">
        <div className="loader"></div>
    </div>;
    return (
        <>
            {/* Hero Section */}
            <section id="hero" className="hero section light-background">
                {/* Dynamically loaded hero image */}
                {/* {imageSrc && <img src={imageSrc} alt="Hero Background" data-aos="fade-in" />} */}
                <LazyLoadImage src={data?.banner_image} alt="Hero Background" data-aos="fade-in" />
                <div className="container position-relative">
                    <div className="welcome position-relative" data-aos="fade-down" data-aos-delay="100">
                        <h2>{data.hero_title || 'Default Hero Title'}</h2>
                        <p>{data.hero_sub_title || 'Default Hero Subtitle'}</p>
                    </div>
                    {/* End Welcome */}

                    <div className="content row gy-4">
                        <div className="col-lg-4 d-flex align-items-stretch">
                            <div className="why-box" data-aos="zoom-out" data-aos-delay="200">
                                <h3>{data.why_choose_title || 'Why Choose Us?'}</h3>
                                <p>{data.why_choose_ || 'Default description for why choose us.'}</p>
                                <div className="text-center">
                                    {data.learn_more_botton?.title && (
                                        <a href={data.learn_more_botton.url || '#'} className="more-btn">
                                            <span>{data.learn_more_botton.title}</span>{' '}
                                            <i className="bi bi-chevron-right"></i>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* End Why Box */}

                        <div className="col-lg-8 d-flex align-items-stretch">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="row gy-4">
                                    {data.why_choose_reapter?.map((item, index) => (
                                        <div className="col-xl-4 d-flex align-items-stretch" key={index} >
                                            <div className="icon-box" data-aos="zoom-out" data-aos-delay="300">
                                                <i className={item.box_icon || 'bi bi-star'}></i>
                                                <h4>{item.box_title || 'Default Box Title'}</h4>
                                                <p>{item.box_subtitle || 'Default Box Subtitle'}</p>
                                            </div>
                                        </div>
                                    ))}
                                    {/* End Icon Box */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Content */}
                </div>
            </section>
            {/* End Hero Section */}
        </>
    );
}
