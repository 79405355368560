import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../context/context'
export default function Doctor() {
	const { data } = useContext(DataContext);
	if (!data) return;
	return (
		<>
			{/* <!-- Doctors Section --> */}
			<section id="doctors" className="doctors section">

				{/* <!-- Section Title --> */}
				<div className="container section-title" data-aos="fade-up">
					<h2>{data.doctors_title}</h2>
					<p>{data.doctors_sub_title}</p>
				</div>
				{/* <!-- End Section Title --> */}

				<div className="container">

					<div className="row gy-4">

						{data.doctor_list.map((data, index) => (
							<div key={index} className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
								<div className="team-member d-flex align-items-start">
									<div className="pic">
										{data?.doctor_picture ? (
											<img src={data?.doctor_picture} alt={data.tab_title} className="img-fluid" />
										) : (
											<p>Loading...</p>
										)}
									</div>
									<div className="member-info">
										<h4>{data.doctor_name}</h4>
										<span>{data.doctor_designation}</span>
										<p>{data.doctor_bio}</p>
										<div className="social">
											<Link to=""><i className="bi bi-twitter-x"></i></Link>
											<Link to=""><i className="bi bi-facebook"></i></Link>
											<Link to=""><i className="bi bi-instagram"></i></Link>
											<Link to=""> <i className="bi bi-linkedin"></i> </Link>
										</div>
									</div>
								</div>
							</div>
						))}
						{/* <!-- End Team Member --> */}
					</div>

				</div>

			</section>
			{/* <!-- /Doctors Section --> */}

		</>
	)
}
